import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import StyledDataGrid from "../../../components/StyledDataGrid";
import { updatePlacementPrebidConfiguration } from "../apiService";

const PlacementPrebidConfigurationsDataGrid = ({
  placement_prebid_configurations,
  setSelectedPlacementIds,
  loading,
  setSnackbar,
}) => {
  /////////// Column definition ///////////

  const columns = [
    {
      field: "placement_id",
      headerName: "Placement ID",
      width: 150,
    },
    {
      field: "prebid_rev_share",
      headerName: "Prebid Hypr Rev Share",
      type: "number",
      width: 250,
      editable: true,
      valueGetter: ({ value }) => {
        if (typeof value == "number") {
          return (value * 100).toFixed(0);
        } else {
          return value;
        }
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        return `${params.value.toLocaleString()} %`;
      },
      valueSetter: (params) => {
        return { ...params.row, prebid_rev_share: params.value / 100 };
      },
      // Client side validation
      preProcessEditCellProps: (params) => {
        let isValid;
        let value = parseInt(params.props.value, 10);

        // returns invalid if value is NaN
        if (isNaN(value)) {
          isValid = false;
        } else {
          isValid = isRevShareValid(value / 100);
        }

        return { ...params.props, error: !isValid };
      },
    },
  ];

  /////////// Row Edition Logic ///////////

  const updatePlacementPrebidConfigurationMutation = useMutation({
    mutationFn: updatePlacementPrebidConfiguration,
    onSuccess: (params) => {
      setSnackbar({
        children: "Configuration successfully updated",
        severity: "success",
      });
    },
    onError: (error) => {
      if (error.status === 422 || error.status === 500) {
        setSnackbar({
          children: "Configuration failed to be updated",
          severity: "error",
        });
      }
    },
  });

  const processRowUpdate = (row) => {
    if (isRevShareValid(row.prebid_rev_share)) {
      updatePlacementPrebidConfigurationMutation.mutate(row);
    }

    return row;
  };

  const processRowUpdateError = (error) => {
    // eslint-disable-next-line no-console
    console.error("Error Updating Row", error);
  };

  /////////// Utils ///////////
  const isRevShareValid = (rev_share) =>
    typeof rev_share === "number" && rev_share >= 0 && rev_share <= 1;

  /////////// Selection Logic ///////////
  const onRowSelectionModelChange = (selected) => {
    setSelectedPlacementIds(selected);
  };

  /////////// Rendering Section ///////////

  return (
    <div style={{ width: "100%" }}>
      <StyledDataGrid>
        <DataGrid
          // Data definition
          rows={placement_prebid_configurations}
          columns={columns}
          getRowId={(row) => {
            return row.placement_id;
          }}
          // Edition
          editMode="cell"
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={processRowUpdateError}
          // Loading configuration
          slots={{
            loadingOverlay: LinearProgress,
            noResultsOverlay: () => null,
            noRowsOverlay: () => null,
          }}
          loading={loading}
          // Selection configuration
          checkboxSelection
          onRowSelectionModelChange={onRowSelectionModelChange}
        />
      </StyledDataGrid>
    </div>
  );
};

export default PlacementPrebidConfigurationsDataGrid;

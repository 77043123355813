import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

// TODO: Move this component to PlacementPrebidConfigurationForm
export const FormInputText = ({
  name,
  control,
  label,
  customValidation = null,
  required = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required, validate: customValidation }}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          name={name}
          helperText={error?.message || null}
          error={!!error?.message}
          onChange={onChange}
          value={value ?? ""}
          fullWidth
          label={label}
          variant="outlined"
          margin="dense"
        />
      )}
    />
  );
};

import { Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PlacementPrebidConfigurationsAddPlacementForm from "./components/PlacementPrebidConfigurationsAddPlacementForm";

export default function BulkCreateDialog(props) {
  const { placement_ids, prebid_account_id, open, onClose, setSnackbar } =
    props;

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Add Placements</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PlacementPrebidConfigurationsAddPlacementForm
                placement_ids={placement_ids}
                prebid_account_id={prebid_account_id}
                onClose={onClose}
                setSnackbar={setSnackbar}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FormInputText } from "../../form-components/form-input-text";
import { createPlacementPrebidConfigurations } from "../apiService";

const PlacementPrebidConfigurationsAddPlacementForm = ({
  placement_ids,
  prebid_account_id,
  onClose,
  setSnackbar,
}) => {
  // Initializes form
  const { control, handleSubmit } = useForm({
    defaultValues: {
      placement_ids: "",
      prebid_rev_share: 30,
    },
  });

  // // Initializes QueryClient
  const queryClient = useQueryClient();

  const createPlacementPrebidConfiguratiosnMutation = useMutation({
    mutationFn: createPlacementPrebidConfigurations,
    onSuccess: (params) => {
      if (params["success"] === false) {
        setSnackbar({
          children: params["error_messages"],
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Placements successfully added",
          severity: "success",
        });
        queryClient.invalidateQueries({
          queryKey: ["PlacementPrebidConfigurations"],
        });
        onClose();
      }
    },
  });

  const submitForm = (data) => {
    data["prebid_account_id"] = prebid_account_id;
    createPlacementPrebidConfiguratiosnMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormInputText
            name="placement_ids"
            control={control}
            label="Placement Ids"
            customValidation={(value) => {
              if (!value) {
                return "Value must not be empty.";
              }

              // it should validate that the value exists
              // and that is a comma separated list of integers
              // it can accept a comma in the end

              const placementIds = value.split(",");

              //allow the last item to be empty
              if (placementIds[placementIds.length - 1] === "") {
                placementIds.pop();
              }

              const areIntegers = placementIds.every((placementId) => {
                const intValue = parseInt(placementId, 10);
                return !isNaN(intValue);
              });

              if (!areIntegers) {
                return "Value must be a comma separated list of integers.";
              }
              return true;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name="prebid_rev_share"
            control={control}
            label="Prebid Hypr Rev Share"
            customValidation={(value) => {
              const intValue = parseInt(value, 10);
              if (isNaN(intValue) || intValue < 0 || intValue > 100) {
                return "Value must be an integer between 0 and 100.";
              }
              return true;
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" type="submit">
            ADD PLACEMENTS
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PlacementPrebidConfigurationsAddPlacementForm;

import { Paper } from "@mui/material";
import { pick } from "radash";
import { json, useLoaderData, useSearchParams } from "react-router-dom";
import { get } from "../apiClient";
import AdvertiserList from "../features/advertisers/AdvertiserList";
import AdvertiserListHeader from "../features/advertisers/AdvertiserListHeader";
import useTitle from "../hooks/useTitle";

const FILTER_PARAMS = ["search", "type"];
const PAGINATION_DEFAULTS = {
  limit: "25",
  offset: "0",
};

const searchParamsToApiParams = (searchParams) => {
  const params = Object.fromEntries(searchParams);

  return {
    ...pick(params, FILTER_PARAMS),
    pagination: {
      ...PAGINATION_DEFAULTS,
      ...pick(params, Object.keys(PAGINATION_DEFAULTS)),
    },
  };
};

const loader = async ({ request }) => {
  const params = searchParamsToApiParams(new URL(request.url).searchParams);

  try {
    const {
      data: advertisers,
      pagination: { total_count: totalCount },
    } = await get("player", "/api/v1/advertisers", {
      params: params,
      signal: request.signal,
    });

    return { params, advertisers, totalCount };
  } catch (error) {
    throw json(
      {
        title: "There was an error fetching the advertisers from the server",
        text: " Please try again. If the error is persistent, contact support.",
      },
      { status: error.status }
    );
  }
};

const ADVERTISER_TYPES = [
  { value: "brand_shopper", text: "Brand / Shopper" },
  { value: "publisher", text: "Publisher" },
  { value: "test_internal", text: "N/A (Test / Internal)" },
];

const Advertisers = () => {
  useTitle("Advertisers");
  const { params, advertisers, totalCount } = useLoaderData();
  const filters = pick(params, FILTER_PARAMS);
  const [searchParams, setSearchParams] = useSearchParams(PAGINATION_DEFAULTS);

  return (
    <>
      <Paper elevation={0} square sx={{ px: 2 }}>
        <AdvertiserListHeader
          advertiserTypes={ADVERTISER_TYPES}
          filters={filters}
        />
        <AdvertiserList
          advertisers={advertisers}
          totalCount={totalCount}
          onPageChange={(_event, page) => {
            setSearchParams((prev) => ({
              ...Object.fromEntries(prev),
              offset: Math.max(page * Number(prev.get("limit")), 0),
            }));
          }}
          onRowsPerPageChange={(event) => {
            setSearchParams((prev) => ({
              ...Object.fromEntries(prev),
              limit: event.target.value,
            }));
          }}
          page={
            Number(searchParams.get("offset")) /
            Number(searchParams.get("limit"))
          }
          rowsPerPage={Number(searchParams.get("limit"))}
        />
      </Paper>
    </>
  );
};

export { loader };

export default Advertisers;

import { destroy, get, patch, post } from "../../apiClient";

export const fetchPlacementPrebidConfiguration = async ({ queryKey }) => {
  let id = queryKey[1];

  try {
    const { data: placement_prebid_configuration } = await get(
      "player",
      `/api/v1/placement_prebid_configurations/${id}.json`
    );

    return placement_prebid_configuration;
  } catch (error) {
    return error;
  }
};

export const fetchPlacementPrebidConfigurations = async ({ queryKey }) => {
  let id = queryKey[1]["prebid_account_id"];

  try {
    const { data: placement_prebid_configurations } = await get(
      "player",
      `/api/v1/prebid_accounts/${id}/placement_prebid_configurations`,
      {
        params: queryKey[1],
      }
    );

    return placement_prebid_configurations;
  } catch (error) {
    return error;
  }
};

// Bulk creation of PPCs
export const createPlacementPrebidConfigurations = async (attributes) => {
  try {
    const placement_prebid_configuration = await post(
      "player",
      `/api/v1/prebid_accounts/${attributes.prebid_account_id}/placement_prebid_configurations`,
      {
        ...attributes,
      }
    );
    return placement_prebid_configuration;
  } catch (error) {
    throw error;
  }
};

export const updatePlacementPrebidConfiguration = async (
  placement_prebid_configuration_attributes
) => {
  try {
    const placement_prebid_configuration = await patch(
      "player",
      `/api/v1/placement_prebid_configurations/${placement_prebid_configuration_attributes.id}`,
      {
        placement_prebid_configuration:
          placement_prebid_configuration_attributes,
      }
    );
    return placement_prebid_configuration;
  } catch (error) {
    throw error;
  }
};

export const deletePlacementPrebidConfiguration = async (id) => {
  try {
    return await destroy(
      "player",
      `/api/v1/placement_prebid_configurations/${id}`
    );
  } catch (error) {
    throw error;
  }
};
